legend {
  width: auto !important;
  float: unset !important;
}

// ? Side Bar
.side-logo-image {
  padding: 0.5rem;
}

// ? Document
.document__image {
  border-top: 4px solid #50aaee;
  background: #ebecee;
  border-radius: 0.8vh;
  padding-top: 75%;
  background-position: center;
  background-size: 100%;
}

.document__image__input {
  height: 100%;
  width: 100%;
  opacity: 0;
}

.document__input {
  height: 100%;
  width: 100%;
  opacity: 0;
}

// ? Quotation
.btn__search__quotation {
  background-color: '#0B121B';
  color: '#EBECEE';
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 40px;
  border-color: transparent;
  :hover {
    background-color: '#0B121B';
  }
}
